.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 60px;
}

.App-title {
  color: thistle;
  font-family: 'Bad Script', cursive;
}

.App-logo {
  width: 12%;
  border-radius: 50%;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .App-header {
    justify-content: center;
    padding-top: 30px;
  }
}
