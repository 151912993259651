i:hover {
  cursor: pointer;
}

.fa {
  color: thistle;
}

.fa:hover {
  color: gold;
}
