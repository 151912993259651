.App {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.App-links {
  color: gold;
  font-weight: 700;
  padding-top: 40px;
}

.App-links:hover,
.App-links:focus {
  cursor: pointer;
  color: thistle;
  text-decoration: none;
}

.App-icon-links {
  letter-spacing: 16px;
}
